exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bantuan-pintar-en-js": () => import("./../../../src/pages/bantuan-pintar.en.js" /* webpackChunkName: "component---src-pages-bantuan-pintar-en-js" */),
  "component---src-pages-bantuan-pintar-js": () => import("./../../../src/pages/bantuan-pintar.js" /* webpackChunkName: "component---src-pages-bantuan-pintar-js" */),
  "component---src-pages-bizzy-connect-en-js": () => import("./../../../src/pages/bizzy-connect.en.js" /* webpackChunkName: "component---src-pages-bizzy-connect-en-js" */),
  "component---src-pages-bizzy-connect-js": () => import("./../../../src/pages/bizzy-connect.js" /* webpackChunkName: "component---src-pages-bizzy-connect-js" */),
  "component---src-pages-bizzy-field-force-en-js": () => import("./../../../src/pages/bizzy-field-force.en.js" /* webpackChunkName: "component---src-pages-bizzy-field-force-en-js" */),
  "component---src-pages-bizzy-field-force-js": () => import("./../../../src/pages/bizzy-field-force.js" /* webpackChunkName: "component---src-pages-bizzy-field-force-js" */),
  "component---src-pages-brand-supplier-en-js": () => import("./../../../src/pages/brand-supplier.en.js" /* webpackChunkName: "component---src-pages-brand-supplier-en-js" */),
  "component---src-pages-brand-supplier-js": () => import("./../../../src/pages/brand-supplier.js" /* webpackChunkName: "component---src-pages-brand-supplier-js" */),
  "component---src-pages-distributor-fmcg-en-js": () => import("./../../../src/pages/distributor-fmcg.en.js" /* webpackChunkName: "component---src-pages-distributor-fmcg-en-js" */),
  "component---src-pages-distributor-fmcg-js": () => import("./../../../src/pages/distributor-fmcg.js" /* webpackChunkName: "component---src-pages-distributor-fmcg-js" */),
  "component---src-pages-iklan-pintar-en-js": () => import("./../../../src/pages/iklan-pintar.en.js" /* webpackChunkName: "component---src-pages-iklan-pintar-en-js" */),
  "component---src-pages-iklan-pintar-js": () => import("./../../../src/pages/iklan-pintar.js" /* webpackChunkName: "component---src-pages-iklan-pintar-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-juragan-grosir-en-js": () => import("./../../../src/pages/juragan-grosir.en.js" /* webpackChunkName: "component---src-pages-juragan-grosir-en-js" */),
  "component---src-pages-juragan-grosir-js": () => import("./../../../src/pages/juragan-grosir.js" /* webpackChunkName: "component---src-pages-juragan-grosir-js" */),
  "component---src-pages-juragan-warung-en-js": () => import("./../../../src/pages/juragan-warung.en.js" /* webpackChunkName: "component---src-pages-juragan-warung-en-js" */),
  "component---src-pages-juragan-warung-js": () => import("./../../../src/pages/juragan-warung.js" /* webpackChunkName: "component---src-pages-juragan-warung-js" */),
  "component---src-pages-karir-en-js": () => import("./../../../src/pages/karir.en.js" /* webpackChunkName: "component---src-pages-karir-en-js" */),
  "component---src-pages-karir-js": () => import("./../../../src/pages/karir.js" /* webpackChunkName: "component---src-pages-karir-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tentang-perusahaan-en-js": () => import("./../../../src/pages/tentang-perusahaan.en.js" /* webpackChunkName: "component---src-pages-tentang-perusahaan-en-js" */),
  "component---src-pages-tentang-perusahaan-js": () => import("./../../../src/pages/tentang-perusahaan.js" /* webpackChunkName: "component---src-pages-tentang-perusahaan-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

